<template>
  <div :id="'pro_'+id" class="product_box product_box_cart" :style="{ border: id*1+1 === length ? 'none':'' }">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="2.56rem" :src="myinfo.saleIconUrl" @click="jumpDetail(myinfo.productId)" />
      <div style="height: 3rem;">
        <div class="pro_name presell_pro-top van-multi-ellipsis--l2" style="width:4.3rem"
             @click="jumpDetail(myinfo.productId)"
        >
          <van-image v-if="myinfo.releaseFlag === 3" width="0.75rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/presell_tag.png"
          />
          {{ myinfo.saleName }}
        </div>
        <div class="pro_brand_model van-ellipsis" @click="jumpDetail(myinfo.productId)">{{ myinfo.proBrand }} |
          {{ myinfo.proModel }}</div>
        <div class=" flex-r-sb-c pro_bottom" style="width:4.3rem">
          <div class="pro_money">￥
            <span style="font-size: 0.43rem;">{{ myinfo.showPrice === 0? myinfo.salePrice :'详询客服' }}</span>
          </div>
          <div class="pro_btn flex-r-c-c">
            <van-stepper v-if="myinfo.num > 0" v-model="myinfo.num" :step="myinfo.minQuantity" :min="0" integer
                         input-width="0.8rem" button-size="0.5rem" theme="round" @change="changeNum"
            />
            <van-icon v-else name="add" color="#0767AB" size="0.55rem" @click="addNum" />
          </div>
        </div>
      </div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
// import { shoppingCart_addUnion } from '@/api/cart'
import { mallPreferential_addCart, mallPreferential_delCart } from '@/api/pro'
export default {
  components: { kefuBtn },
  props: {
    info: { type: Object, default: () => { } },
    id: { type: Number, default: null },
    length: { type: Number, default: null },
    cannum: { type: Number, default: null }

  },
  data() {
    return {
      myinfo: this.info,
      mycanBuyNum: this.cannum === -1 ? 9999 : this.cannum

    }
  },
  methods: {

    // 进入详情
    jumpDetail(productId) {
      sessionStorage.setItem('classify_pro_id', 'pro_' + this.id)
      this.$router.push('/product/detail_cart?productId=' + productId)
    },
    addNum() {
      mallPreferential_addCart({ productId: this.myinfo.productId, num: parseInt(this.myinfo.minQuantity) }).then(res => {
        this.myinfo.num = parseInt(this.myinfo.minQuantity)
        this.$emit('changeNum', res.data)
      })
    },
    // 修改数量
    changeNum(val) {
      var minQuantity = parseInt(val) % parseInt(this.myinfo.minQuantity) === 0
      console.log(minQuantity)
      if (parseInt(val) > 0 && minQuantity) {
        mallPreferential_addCart({ productId: this.myinfo.productId, num: val }).then(res => {
          this.$emit('changeNum', res.data)
        })
      } else {
        mallPreferential_delCart({ productId: this.myinfo.productId }).then(res => { this.$emit('changeNum', res.data) })
        this.myinfo.num = 0
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style>
.pro_btn .van-stepper--round .van-stepper__minus {
  border: 0.03rem solid #e2e2e2;
  color: #000;
}
.pro_btn .van-stepper--round .van-stepper__plus {
  background-color: rgba(7, 103, 171, 1);
}
.product_box_cart {
  border-bottom: 2px solid #f7f7f7;

  margin-top: 0rem;
}
</style>
